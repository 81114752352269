<template>
    <div id="m_guide">
        <div class="top d_flex">
            <div class="logo"><img :src="$store.state.tenantInfo.logo | fullPath" alt=""></div>
            <div class="right_content d_flex">
                <template v-if="!isLogin">
                    <span class="login" @click="$router.push('/m_login')">登录</span>
                    <span class="registered" @click="$router.push('/m_register')">注册</span>
                </template>
                <div class="user" v-else><img src="@/assets/img/mobile/10.png" alt=""></div>
                <span @click="show = !show"><img src="@/assets/img/mobile/nav_bar.png" alt=""></span>
            </div>
        </div>
        <div class="subnav_content fr">
            <div class="user_temp">
                <div id="guide">
                    <div class="admin_main_block">
                        <div class="user_temp_title">{{article.title}}</div>
                        <div class="admin_table_main" v-html="article.content">
                            <!-- <p class="p1 font1">1.平台是什么类型的网站？</p>
                            <p>&nbsp;</p>
                            <p>①平台是采购与发货一站式服务的专业礼品代发平台，例如电商需要赠送礼物给买家，采购、仓储、打包、发货这些环节我们都帮您完成！</p>
                            <p>&nbsp;</p>
                            <p>②本站自建广州韵达仓储/广州申通仓储，海量礼品选择，百万级采购，礼品最低只要0.1元/单，行业最低。</p>
                            <p>&nbsp;</p>
                            <p>③本站支持秒出单号，实物发货，提供底单，并提供淘宝插件一键下单发货，拼多多工具一键下单发货，省时省力！</p>
                            <p>&nbsp;</p>
                            <p>④日均发单≥500笔可直接联系客服申请内部特价，给予绝对价格优势！</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p class="p1 font1">2.礼品代发的发货流程是怎么样的，有没有下单教程？</p>
                            <p>&nbsp;</p>
                            <p>平台发货流程：</p>
                            <p>&nbsp;</p>
                            <p>1.登入网站 — 2. 选择仓储&nbsp;— &nbsp;&nbsp;3.选择礼品 &nbsp;— &nbsp;&nbsp;4.填写地址 &nbsp;—&nbsp;&nbsp; 5. 下单成功 &nbsp; — &nbsp;&nbsp;&nbsp;&nbsp; 6.平台出单号&nbsp; — &nbsp;&nbsp;&nbsp;&nbsp; 7.批量发货</p>
                            <p>&nbsp;</p>
                            <p>代发视频教程：</p>
                            <p>&nbsp;</p>
                            <p>①淘宝下单操作视频（内含淘宝手动下单，表格导入下单，表格筛选下单教程）：<a class="db" style="color: #f9963b;" href="https://www.fabwang.com/files/video/%E6%B7%98%E5%AE%9D%E4%B8%8B%E5%8D%95%E6%95%99%E7%A8%8B.mp4">点我观看视频教程</a></p>
                            <p>&nbsp;</p>
                            <p>②淘宝插件下单视频（推荐使用，利用本平台自开发插件在淘宝一键下单发货）：<a class="db" style="color: #f9963b;" href="https://www.fabwang.com/files/video/%E6%B7%98%E5%AE%9D%E6%8F%92%E4%BB%B6%E5%8F%91%E8%B4%A7%E6%95%99%E7%A8%8B.mp4">点我观看视频教程</a></p>
                            <p>&nbsp;</p>
                            <p>③拼多多下单操作视频（内含拼多多手动下单，表格导入下单，表格筛选下单教程）：<a class="db" style="color: #f9963b;" href="https://www.fabwang.com/files/video/%E6%8B%BC%E5%A4%9A%E5%A4%9A%E4%B8%8B%E5%8D%95%E6%95%99%E7%A8%8B.mp4">点我观看视频教程</a></p>
                            <p>&nbsp;</p>
                            <p>④拼多多一键下单发货教程（推荐使用，利用打单工具一键下单发货，无需在手动复制地址）：<a class="db" style="color: #f9963b;" href="https://www.fabwang.com/files/video/%E6%8B%BC%E5%A4%9A%E5%A4%9A%E4%B8%80%E9%94%AE%E4%B8%8B%E5%8D%95%E6%95%99%E7%A8%8B.mp4">点我观看视频教程</a></p>
                            <p>&nbsp;</p>
                            <p>⑤京东下单教程：（内含京东手动下单，表格导入下单，表格筛选下单教程）：<a class="db" style="color: #f9963b;" href=https://www.fabwang.com/files/video/%E4%BA%AC%E4%B8%9C%E4%B8%8B%E5%8D%95%E6%95%99%E7%A8%8B.mp4"">点我观看视频教程</a></p>
                            <p>&nbsp;</p>
                            <p>本站下单方式：手动填写、表格导入、智能筛选、淘宝插件一键下单发货、拼多多工具一键下单发货，绝对行业下单标杆！</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p class="p1 font1">3 、发货地址不一样，对我们商家安全吗？  </p>
                            <p>&nbsp;</p>
                            <p>①我们可以非常肯定的告诉您，风险系数为0 ，我们和小二都确认过的，发货地址不一样没有任何问题。</p>
                            <p>&nbsp;</p>
                            <p>②目前淘宝大力推广一件代发，共享云仓，皆为一个店铺多仓发货， 做一件代发的商家， 上架10款产品，可能有9个发货地很正常。</p>
                            <p>&nbsp;</p>
                            <p style="font-weight: bold;">③并且现在天猫超市也是开始在每个地区储备仓库，以提高发货速度，所以完全不必为发货地不一样而担心！ </p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p class="p1 font1">4、这个平台安全吗？</p>
                            <p>&nbsp;</p>
                            <p>①我们是一个真实的礼品代发平台，所有东西均为实物，并且只售真品，绝不售假货 ，快递为韵达/申通两个仓库</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p class="p1 font1">5、多久会出物流？发货速度怎么样？物流更新怎么样？ </p>
                            <p>&nbsp;</p>
                            <p>①平台是真实的实物发货，不是空包裹，所以流程和您平常发货的是一样的，平台秒出快递单号</p>
                            <p>&nbsp;</p>
                            <p>②关于物流更新：当天18.30点前下的订单，当天会打包发货完成出物流。</p>
                            <p>&nbsp;</p>
                            <p>③超过18.30的订单次日10点之前会揽收，18点之前打包发货完成，请合理安排发货时间。  </p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p class="p1 font1">6、有问题件，或者我需要底单怎么解决？ </p>
                            <p>&nbsp;</p>
                            <p>①平台问题件很少，如果有问题件可以联系我们平台的客服，我们会第一时间为您解决，</p>
                            <p>&nbsp;</p>
                            <p>②关于底单：淘宝=淘宝菜鸟面单，拼多多=拼多多电子面单，京东=通用面单 </p>
                            <p>&nbsp;</p>
                            <p>③如需申诉，不需要底单，后台直接获取即可。</p>
                            <p>&nbsp;</p>
                            <p>④如特殊情况需要底单，可直接在在平台申请底单，我们将会在24小时内提供。</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p class="p1 font1">7、平台支持批量发货吗？</p>
                            <p>&nbsp;</p>
                            <p>①支持淘宝，拼多多，京东等一键下单一键发货，我们有着专业领先的技术，再多订单几分钟即可发完，各位放心使用！</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p class="p1 font1">8、为什么平台的快递和礼品相比别的代发网这么便宜？</p>
                            <p>&nbsp;</p>
                            <p>①我们和仓储公司建立了战略合作关系，能拿到最低的价格，而且在商家身上我们基本是不赚利润。</p>
                            <p>&nbsp;</p>
                            <p>②给您的快递价格不仅仅是快递价格，里面还包含了快递成本、人工打包成本、场地成本、包装袋成本。</p>
                            <p>&nbsp;</p>
                            <p>③礼品采购量的都是以百万级采购的，不管任何产品，我们都需要拿到最低的价格，才能给商家朋友们尽可能低的价格。</p>
                            <p>&nbsp;</p>
                            <p>④平台保证全部订单为真实发货，不会虚假发货，同时我们也严厉排斥发虚假物流的礼品网，这是对商家的严重不负责任！</p>
                            <p></p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom_content d_flex">
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom1.png" alt="">
                    <p>
                        <span>降低成本</span><br>
                        <span>帮你降低50%发货成本</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom2.png" alt="">
                    <p>
                        <span>保证品质</span><br>
                        <span>注重品质，物廉价美</span>
                    </p>
                </div>
            </div>
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom4.png" alt="">
                    <p>
                        <span>去除库存</span><br>
                        <span>回收库存、去礼品库</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom3.png" alt="">
                    <p>
                        <span>多种选择</span><br>
                        <span>低价礼品、配重礼品、高端礼品</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="friend_link">
            <span>友情链接：</span>
            <a :href="item.url" v-for="item in linksList" target="_blank">{{item.name}}</a>
        </div>
        <div class="copyright" v-if="$store.state.tenantInfo.beianhao">
            <img src="@/assets/img/guohui.png" alt="">
            {{$store.state.tenantInfo.beianhao}}
        </div>
        <transition name="fade">
            <div class="mask" v-if="show" @click="show=false">
            </div>
        </transition>
        <transition name="fade1">
            <div class="mask_left" v-if="show">
                <div @click="$router.push('/m_index')">首页</div>
                <div @click="$router.push('/m_goodsall')">礼品商城</div>
                <div @click="$router.push('/member/index')">会员中心</div>
                <div @click="$router.push('/member/vip')">收费标准</div>
                <div @click="$router.push('/member/guide')">代发指南</div>
                <!-- <div v-if="$store.state.showOtherSite" @click="$router.push('/member/other_site')">源码搭建</div> -->
                <div @click="openQQ">在线客服</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            isLogin:false,
            show:false,
            article:{
                title:'',
                conent:'',
            },
        }
    },
    created(){
        this.getArticleList();
        let token = localStorage.getItem('dftToken')
        if(token){
            this.isLogin = true
        }else{
            this.isLogin = false
        }
        // this.IsPC()
    },
    mounted(){},
    watch: {},
    methods: {
        //获取最新一条代发指南
        getArticleList(){
            this.$request.post({
                url:'/bus/article/public/list',
                params:{
                    pageNo:1,
                    pageSize:1,
                    type:6,
                    isConent:1,
                },
                success: res => {
                    if(res.list.length>0){
                        this.article = res.list[0];
                    }
                }
            })
        },
        openQQ() {
            var url = 'tencent://message/?uin=' + this.$store.state.tenantInfo.qq + '&amp;Site=&amp;Menu=yes';
            window.open(url,'_self')
        },
        IsPC(){  
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
            var flag = true;  
            for (var v = 0; v < Agents.length; v++) {  
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
            }  
            if(flag){
                this.$router.push('/member/guide')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#m_guide{
    padding-top: 44px;
    .top{
        position: fixed;
        top: 0;
        background: #ffffff;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        padding: 0 14px;
        height: 44px;
        border: 1px solid #F5F5F5;
        z-index: 10;
        .logo{
            align-items: center;
            width: 57.6px;
            img{
                width: 100%;
                display: block;
            }
        }
        .right_content{
            align-items: center;
            span{
                &:last-child{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    img{
                        width: 100%;
                    }
                }
            }
            .login{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                background: #FF8800;
                border-radius: 12px;
                padding: 2px 10px;
            }
            .registered{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF8800;
                padding: 2px 10px;
                border: 1px solid #ff8800;
                border-radius: 12px;
                margin: 0 14px 0 5px;
            }
            .user {
                width: 18px;
                height: 18px;
                display: block;
                margin-right: 18px;
                img{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }
    .subnav_content{
        width: auto;
        background: #fff;
        margin-bottom: 0;
        padding-bottom: 20px;
        .user_temp{
            #guide{
                .user_temp_title{
                    border-bottom:0;
                    padding-left:0;
                    margin-bottom: 30px;
                    padding-bottom: 0;
                    font-weight: 700;
                }
                .db{
                    display: block;
                }
                .admin_main_block{
                    padding: 30px 22px 0;
                    .admin_table_main{
                        .p1{
                            font-weight: bold;
                        }
                        .font1{
                            color: #f9963b;
                        }
                    }
                }
            }
        }
    }
    .bottom_content{
        padding: 0 14px;
        margin-top: 10px;
        .b_item{
            flex: 1;
            div{
                img{
                    width: 74px;
                }
                p{
                    margin-top: 15px;
                    span{
                        &:first-child{
                            color: #000000;
                            font-size: 14px;
                            font-weight: bold;
                        }
                        &:last-child{
                            font-size: 10px;
                            color: #999;
                        }
                    }
                }
            }
        }
    }
    .friend_link{
        margin-top: 15px;
        text-align: center;
        span{
            font-size: 10px;
            color: #333333;
            &:first-child{
                color: #666666;
            }
        }
        a{
            margin-right: 5px;
            font-size: 10px;
        }
    }
    .copyright{
        text-align: center;
        font-size: 8px;
        color: #333333;
        margin-top: 9px;
        padding-bottom: 42px;
        img{
            height: 16px;
        }
    }
    .mask{
        position: fixed;
        top: 44px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        z-index: 11;
    }
    .mask_left{
        width: 35%;
        text-align: center;
        position: fixed;
        top: 44px;
        background: #fff;
        bottom: 0;
        right: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        z-index: 11;
        div{
            padding: 9px 0;
            border-bottom: 1px solid #eee;
        }
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade1-enter, .fade1-leave-to {
        transform: translateX(100%);
    }
    .fade1-enter-active, .fade1-leave-active {
        transition: all .5s;
    }
}
</style>